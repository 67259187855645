.mapContainer {
    width: 100%;
    height: 100%;
}

.mapboxgl-canvas {
    position: absolute;
}

.mapboxgl-ctrl-bottom-right {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 0 6px 12px;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
    padding: 0 5px;
    background-color: rgba(255, 255, 255, 0.5);
    margin: 0;
}

.mapboxgl-marker {
    width: 25px;
    position: absolute;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
}

.mapboxgl-popup-close-button {
    font-size: 15px;
}

.mapboxgl-popup-content {
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
    padding: 15px 20px 10px 10px;
    min-width: 100px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}

.mapboxgl-popup-content-wrapper {
    padding: 1%;
}