.react-datepicker{
  border-bottom-right-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  border-radius: unset;
  font-size: 10px;
}

.react-datepicker__current-month {
  font-size: 12px;
}


/* Style weekends*/
.react-datepicker__day--disabled {
  color: black !important;
  border-radius: 0.3rem;
  background-color: #a7a2a270 !important;
}


/* Style jour férié */
.react-datepicker__day--highlighted-custom-3 {
  color: black !important;
  border-radius: 0.3rem;
  background-color: #a7a2a270 !important;
}

/* Style intervention passé*/
.react-datepicker__day--highlighted-custom-1 {
  font-weight: bold;
  color: white !important;
  border-radius: 0.3rem;
  background-color: #349034ed !important;
}

/* Style prochaine intervention */
.react-datepicker__day--highlighted-custom-2 {
  font-weight: bold;
  color: white !important;
  border-radius: 0.3rem;
  background-color: #ff8100 !important;
}

/* Style date max */
.react-datepicker__day--Date-Max {
  font-weight: bold;
  color: white !important;
  border-radius: 0.3rem;
  background-color: #ff0000 !important;
}

.react-datepicker__day--today {
  background-color: #2579ba;
  color: white;
}

.react-datepicker__day--selected {
  color: white !important;
}

.react-datepicker__day {
  outline: none;
  cursor: unset;
}

.react-datepicker__month {
    margin: 0.2rem;
}

.react-datepicker__day-name, .react-datepicker__day {
    font-size: 10px;
    width: calc(10px + (20 - 10) * ((100vw - 900px) / (1900 - 900)));
    line-height: calc(10px + (20 - 10) * ((100vw - 900px) / (1900 - 900)));
    margin: 0.166em;
}

.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: -4px;
  outline: none;
}

.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: -4px;
  outline: none;
}

@media (max-width: 1300px) {
  .react-datepicker__day {
    font-size: 8px;
    width: calc(10px + (15 - 10) * ((100vw - 900px) / (1900 - 900)));
    line-height: calc(10px + (15 - 10) * ((100vw - 900px) / (1900 - 900)));
  }

  .react-datepicker__day-name {
    font-size: 8px;
    width: calc(10px + (20 - 10) * ((100vw - 900px) / (1900 - 900)));
    line-height: 1.3em;
  }

  .react-datepicker__current-month {
    font-size: 8px;
  }

  .react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: -3px;
  }

  .react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: -3px;
  }
}